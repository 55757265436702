document.addEventListener("DOMContentLoaded", function() {

    const menuForm = document.getElementById('menuForm');
    const rangeElements = document.querySelectorAll('.form-range');

    //init range inputs
    if (menuForm) {
        const inputEvent = new Event('input');
        rangeElements.forEach(function (element) {
            element.dispatchEvent(inputEvent);
        });
    }

});

function updateRangeValue(input) {
    const target = input.getAttribute('data-target');
    document.getElementById(target).innerText = input.value;
}

window.updateRangeValue = updateRangeValue;
